body {
    background-color: #808080;
    margin: 0;
    font-family: 'Chivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.brand-logo div {
    display: inline-block;
}

.col input,
.col input[type='date'],
.col input[type='tel'],
.col textarea {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 2em;
    text-indent: 0.75em;
}

.col textarea {
    height: 4em;
}

.col label {
    font-size: 1em;
    color: #363636;
    display: block;
    padding-top: 1em;
}

.page {
    background-color: #f2f2f2;
    border-radius: 2em;
    margin: 0.6em 1em 0 1em;

    padding: 1em;
}

form .row {
    margin-bottom: 0.25em;
}

fieldset form {
    margin: 1em 0 0 0;
}

footer {
    background-color: #1e1b1a;
    border-radius: 2em 2em 0 0;
    color: #fff;
    /* fill the bottom of the page */
    flex: 1 0 auto;
    margin: 0.6em 1em 0 1em;
}

footer > div {
    color: rgb(255, 255, 255, 0.5);
    /* fill horizontally */
    flex: 1 0 auto;
    font-size: 0.8em;
    font-style: italic;
    letter-spacing: 0.2em;
    margin: 4em 0;
    text-transform: uppercase;
}

footer .line {
    display: block;
}

h1,
h2 {
    color: rgb(244, 187, 38);
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
}

h1 {
    font-size: 2.3em;
    letter-spacing: 0.1em;
}

h2 {
    font-size: 1.75em;
}

header section {
    background-color: #333;
    padding: 2.75em 0 1em 0;
}

header,
header a {
    color: rgb(244, 187, 38);
    margin: 0 1em;
    text-decoration-line: none;
    text-transform: uppercase;
}

nav {
    border-radius: 0 0 2em 2em;
    height: 50px;
    line-height: 50px;
}

nav,
nav ul a {
    background-color: rgb(244, 187, 38);
    color: #fff;
    text-decoration-line: none;
}

nav ul a:hover {
    background-color: rgb(90, 90, 90);
}

nav ul li {
    display: inline-block;
    float: none;
}

@media only screen and (max-width: 600px) {
    .col label {
        padding-top: 0;
    }

    .page,
    footer,
    header {
        margin-left: 0;
        margin-right: 0;
    }
}
