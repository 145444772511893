.actionBtn,
.actionBtn:active,
.actionBtn:focus {
    background-color: #4caf50;
}
.actionBtn:hover {
    background-color: #6bff70;
    color: #333;

    transition: color 0.2s ease-out;
}

.btnBaseline {
    line-height: 2.4em;
}

.fullWidth {
    width: 100%;
}
