p {
    color: rgb(128, 128, 128);
    font-size: 0.9rem;
    line-height: 1.2em;
    /* edge up a little closer to the header */
    margin-top: -1em;
}

strong {
    font-weight: 900;
}

a {
    color: #171717;
    text-decoration-line: underline;
}

a:hover {
    text-decoration-line: none;
}
