.error {
    background-color: #f44336;
}

.error p {
    color: #000;
}

.success {
    background-color: #4caf50;
}
